import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";

const Header = () => {
  let Logo = require("../../../assets/images/mainImage.png");
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleLinkClick = (event: { preventDefault: () => void; currentTarget: { hash: any; }; }) => {
    event.preventDefault();
    const { hash } = event.currentTarget;
    const element = document.querySelector(hash);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setShowMenu(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.pageYOffset > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className={`header main__background ${isSticky ? 'sticky' : ''}`}>
      <div className="header__container container mx-auto flex justify-between items-center">
        <a className="header__logo flex font-medium items-center text-gray-900 mb-4 md:mb-0" href="/">
          <img src={Logo} alt="logo" className="h-15 md:h-14" />
        </a>
        <button
          className="menu-button md:hidden"
          aria-label="Menu"
          onClick={() => setShowMenu(!showMenu)}
        >
          <span style={{ top: showMenu ? "8px" : "0px", transform: showMenu ? "rotate(45deg) translate(3px, 3px)" : "none" }}></span>
          <span style={{ opacity: showMenu ? "0" : "1" }}></span>
          <span style={{ top: showMenu ? "8px" : "16px", transform: showMenu ? "rotate(-45deg) translate(3px, -3px)" : "none" }}></span>
        </button>
        <nav className={`header__nav md:flex flex-wrap items-center text-base justify-center ${showMenu ? 'open' : ''}`}>
          <a className="header__link" href="/" onClick={() => { navigate("/"); setShowMenu(false); }}>
            Home
          </a>
          <a className="header__link" href="/#about" onClick={handleLinkClick}>
            About
          </a>
          <a className="header__link" href="/#shippers" onClick={handleLinkClick}>
            Shippers
          </a>
          <a className="header__link" href="/#carriers" onClick={handleLinkClick}>
            Carriers
          </a>
          <a className="header__link" href="/#contact" onClick={handleLinkClick}>
            Contact
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
