import React, { useState, useRef, useEffect } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import Header from "../../../components/Navigation/Header/Header";
import "./FAQs.css";

export default function FAQs() {
  const qaPairs = [
    {
      question:
        "How is LoadNepal different from other transportation providers?",
      answer:
        "LoadNepal combines the reliability of asset-based carriers, the flexibility of brokers, and unique benefits that only a digital freight network can offer. By automating tender acceptance, brokering, and pricing, we help you save time and reduce your shipping costs.",
    },
    {
      question: "What services do you offer to shippers?",
      answer:
        "We provide Full Truckload service with either Open dhala body or Container (Dabba) body, with load capacity of 10 Tons(6 wheels), 17 Tons (10 wheels) and 21 Tons (12 Wheels).",
    },
    {
      question: "How do you vet carriers in your network?",
      answer:
        "LoadNepal holds carriers in our network to the industry’s highest standards in order to provide shippers with higher tender acceptance, better on-time performance, and fewer safety incidents. Our compliance team and algorithm models continuously evaluate carriers with input from both industry regulators and customers to build one of the industry’s safest, most reliable truckload networks.",
    },
    {
      question: "Will LoadNepal let me know where my shipment is after it’s left my facility?",
      answer:
        "Yes. We offer 24/7 visibility through live GPS tracking on your shipments, enabling tracking from the point where a load is accepted by one of our...",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef(qaPairs.map(() => React.createRef()));
  const [activeIndexes, setActiveIndexes] = useState([]);


  useEffect(() => {
    if (activeIndex !== null) {
      const contentRef = contentRefs.current[activeIndex].current;
      contentRef.style.maxHeight = `${contentRef.scrollHeight}px`;
    }
  }, [activeIndex]);

  const toggleAccordion = (index) => {
    setActiveIndexes((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((i) => i !== index);
      } else {
        // Close all other active sections
        const newActiveIndexes = prevState.filter((i) => i !== index);
        contentRefs.current.forEach((ref, i) => {
          if (i !== index) {
            ref.current.style.maxHeight = null;
          }
        });
        return [...newActiveIndexes, index];
      }
    });
  };
    
  return (
    <>
      <Header />
      <div className="faq-container">
        {qaPairs.map((qaPair, index) => (
          <div key={index}>
            <button
              className="question-section"
              onClick={() => toggleAccordion(index)}
            >
              <div className="question-wrapper">
                <h4 className="question-style">{qaPair.question}</h4>
                <div className="question-icon-wrapper">
                  {activeIndex === index ? (
                    <FiMinus className="question-icon" />
                  ) : (
                    <FiPlus className="question-icon" />
                  )}
                </div>
              </div>
            </button>
            <div
              ref={contentRefs.current[index]}
              className={
                activeIndex === index
                  ? "answer answer-show p-4"
                  : "answer p-4"
              }
            >
              <p className="answer-text">{qaPair.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
