import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RouteObj from "./routes";


interface Iprops {
  path: string;
  component: any;
}

function App() {
  return (
    <React.Fragment >
      <Suspense>
        <ToastContainer />
        <Routes>
          {RouteObj.map((e: Iprops, i: number) => (
            <Route path={e.path} key={i} element={<e.component />} />
          ))}
         
        </Routes>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
