
import LandingPage from "./pages/LandingPage";



import TermsConditions from "./pages/LandingPage/TermsConditions";
import PrivacyPolicies from "./pages/LandingPage/PrivacyPolicies";
import FAQs from "./pages/LandingPage/FAQs/FAQs";


const RouteObj = [
  {
    path: "/",
    component: LandingPage,
  },

  //terms & condition
  {
    path: "/terms-conditions",
    component: TermsConditions,
  },
  {
    path: "/privacy-policies",
    component: PrivacyPolicies,
  },
  {
    path: "/faqs",
    component: FAQs,
  },
];



export default RouteObj;
