import React from "react";
import { FaFacebook, FaTwitter, FaInstagram,FaLinkedin } from 'react-icons/fa';

import "./Footer.css"
let Logo = require("../../../assets/images/mainImage.png");



function Footer() {
  return (
    <footer className="bg-gray-100">
      <div className="container mx-auto py-10 px-5 flex flex-wrap">
        <div className="w-full md:w-1/3 mb-10 md:mb-0">
          <a href="/" className="flex items-center">
            <img src={Logo} alt="Logo" className="w-25 h-12 mr-2" />
          </a>
          <p className="text-gray-600 mt-2">
            Butwal-8 Ruphendehi, Nepal<br />
            Phone : +977 9857079184<br />
            Email : info@zeroneintertech.com.np
          </p>
        </div>
        <div className="w-full md:w-1/3 mb-10 md:mb-0">
          <h2 className="text-lg font-bold text-gray-900 mb-4">Useful Links</h2>
          <ul className="list-none">
            <li>
              <a href="/" className="text-gray-600 hover:text-gray-900 hover:underline block mb-2">Home</a>
            </li>
            <li>
              <a href="/about" className="text-gray-600 hover:text-gray-900 hover:underline block mb-2">About</a>
            </li>
            <li>
              <a href="/services" className="text-gray-600 hover:text-gray-900 hover:underline block mb-2">Services</a>
            </li>
            <li>
              <a href="/blog" className="text-gray-600 hover:text-gray-900 hover:underline block mb-2">Blog</a>
            </li>
            <li>
              <a href="/contact" className="text-gray-600 hover:text-gray-900 hover:underline block mb-2">Contact</a>
            </li>
            <li>
              <a href="/faqs" className="text-gray-600 hover:text-gray-900 hover:underline block">FAQs</a>
            </li>
          </ul>
        </div>
        <div className="w-full md:w-1/3">
          <h2 className="text-lg font-bold text-gray-900 mb-4">Legal</h2>
          <ul className="list-none">
            <li>
              <a href="#" className="text-gray-700 hover:text-purple-600 transition-colors duration-200">
                Privacy Policy
                <div className="h-0.5 bg-purple-600 absolute bottom-0 left-0 right-0 transform scale-x-0 transition-transform duration-200"></div>
              </a>
            </li>
            <li>
              <a href="/terms-conditions" className="text-gray-600 hover:text-gray-900 hover:underline block">Terms and Conditions</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="container mx-auto py-4 px-5 flex justify-between items-center border-t border-gray-300">
        <div className="text-sm text-gray-600">
          © 2023 Zerone Intertech. All rights reserved.
        </div>

        <div>
          Designed and Developed by <a href="https://www.zeroneintertech.com.np" className="text-purple-800 hover:text-purple-600 underline">Zerone Intertech</a>.
        </div>
        <div>
          <ul className="flex space-x-4">
            <li>
              <a href="https://www.facebook.com/profile.php?id=100088506432603" className="text-gray-600 hover:text-purple-600 transition-colors duration-200">
                <FaFacebook className="text-2xl"/>
              </a>
            </li>
            <li>
              <a href="#" className="text-gray-600 hover:text-purple-600 transition-colors duration-200">
                <FaTwitter className="text-2xl"/>
              </a>
            </li>
            <li>
              <a href="#" className="text-gray-600 hover:text-purple-600 transition-colors duration-200">
                <FaInstagram className="text-2xl"/>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/loadnepal/?viewAsMember=true" className="text-gray-600 hover:text-purple-600 transition-colors duration-200">
                <FaLinkedin className="text-2xl"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
     
    </footer>
  );
}


export default Footer;
